







































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName, apiUrl } from "@/env";
import { readLoginError } from "@/store/main/getters";
import {
  dispatchSignup,
  dispatchGoogleLogin,
  dispatchFacebookLogin,
  dispatchAppleLogin,
  dispatchSignupVerify,
} from "@/store/main/actions";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Loading from "./Loading.vue";
import TCSignup from "./TCSignup.vue";
import TCPSignup from "./TCPSignup.vue";
import { Providers } from "universal-social-auth";
import { readUserProfile } from "@/store/main/getters";
import UniversalSocialauth from "universal-social-auth";
// Button M2
import {
  Github,
  Facebook,
  Google,
  Twitter,
  Apple,
} from "universal-social-auth";
import { googleClientId, appUrl, facebookClientId } from "@/env";
import axios from "axios";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    Header,
    Footer,
    Loading,
    TCSignup,
    TCPSignup,
  },
  props: {
    login: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Apply extends Vue {
  public valid = true;

  public responseData = {
    code: "",
    provider: null,
  };
  public hash = "";
  public data = {
    tok: "",
  };
  public fauth = false;
  public Google = Google;
  public Facebook = Facebook;

  public shouldShow() {
    return !(
      apiUrl.startsWith("https://dev-op") || apiUrl.startsWith("https://op")
    );
  }
  public useAuthProvider(provider, proData) {
    const pro = proData;
    const ProData = pro || Providers[provider];

    const options = {
      providers: {
        // apple: {
        //   nonce: '**************',
        //   state: '**************',
        //   clientId: '**************',
        //   redirectUri: 'https://myapp.com/auth/github/callback'
        // },
        // github: {
        //  clientId: "",
        //  redirectUri: "https://diadal.com.ng/auth/github/callback",
        // },
        google: {
          clientId: googleClientId,
          redirectUri: `${appUrl}/apply`,
        },
        facebook: {
          clientId: facebookClientId,
          redirectUri: `${appUrl}/apply`,
        },
        // twitter: {
        //   url: 'https://myapp.com/auth/twitter',
        //   clientId: '********',
        //   redirectUri: 'https://myapp.com/auth/twitter/callback'
        // }
      },
    };
    const Oauth = new UniversalSocialauth(axios, options);
    Oauth.authenticate(provider, ProData)
      .then((response) => {
        const rsp = response;
        if (rsp.code) {
          this.responseData.code = rsp.code;
          this.responseData.provider = provider;
          setTimeout(() => {
            this.useSocialLogin(provider);
          }, 100);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  public async useSocialLogin(provider) {
    // otp from input Otp form
    // hash user data in your backend with Cache or save to database

    const pdata = {
      code: this.responseData.code,
      //hash: this.hash,
      redirect_uri: `${appUrl}/apply`,
    };
    if (provider === "google") {
      await dispatchGoogleLogin(this.$store, pdata);
    } else if (provider === "facebook") {
      await dispatchFacebookLogin(this.$store, pdata);
    }
  }
}
